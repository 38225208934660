.Meeting_heading {
    width: 400px !important;
    /* color: black !important; */
    margin-bottom: 32px !important;
    margin-left: 32px !important;
    margin-right: 32px !important;
}

.basic-plan-label {
    margin-left: 32px;
}

.box-main-wrapper {
    padding-top: 100px !important;
}

.box-main-wrapper>img {
    width: 200px;
    height: 50px;
}

.basic-plan-label>label {
    margin-left: 8px;
}

.plan-category-btn {
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
}

.plan-category-btn>button {
    color: #fff;
    width: 222px;
    height: 48px;
    padding: 10px;
    margin-top: 1rem;
    border-radius: 12px;
    text-transform: none;
    background-color: #0057D3 !important;
    outline: none;
    border: #0057D3;
}


.css-rjulde-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select {
    padding: 0px !important;
}