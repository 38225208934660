@import 'sweetalert2/src/sweetalert2.scss';

.tableInput input {
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
}

.tableSearchButton {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-image {
    width: 100%;
    height: 50px;
    display: block;
    margin: 10px 0;
}

.css-cxb90s-MuiCircularProgress-root {
    color: #1A73E8 !important;

}

.active-btn {
    padding: 8px 20px;
    outline: none;
    background: linear-gradient(195deg, #49a3f1, #1A73E8);
    color: #e5e5e5;
    border: 1px solid #1A73E8;
}

.block-btn {
    background: linear-gradient(195deg, #EC407A, #D81B60);
    color: #e5e5e5;
    border: 1px solid #EC407A;
    padding: 8px 20px;
    outline: none;
}

.tableSearchButton {
    border: 1px solid #1A73E8;
    background: linear-gradient(195deg, #49a3f1, #1A73E8);
    color: white;
}

// .css-168isbf-MuiPaper-root-MuiAppBar-root {
//     top: 0px !important;
// }