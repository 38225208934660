.intl-tel-input{
    width: 100% !important;
    border-bottom: 1px solid #d2d6da;
}
.intl-tel-input:focus{
    width: 100% !important;
    border-bottom: 1px solid #d2d6da;
}

.form-control .intl-tel-input-phone:focus{
    width: 100% !important;
    border-bottom: 1px solid #d2d6da;
    outline: none;
}

.form-control:focus{
    box-shadow: none !important;
}

.intl-tel-input-phone {
    position: relative;
    z-index: 0;
    margin-top: 0px !important;
    margin-bottom: 0 !important;
    padding-right: 36px;
    margin-right: 0;
    height: 50px !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    border: 1px solid transparent !important;
  }

  .intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code{
    padding-left: 2px !important;
    font-size: 14px !important;
  }

  .profileInputError{
    font-size: 0.75rem !important;
    font-weight: 300;
    color: #F44335;
  }
  .intl-tel-input .flag-container .arrow{
    display:none !important;
  }
  
  .intl-tel-input.allow-dropdown.separate-dial-code .selected-flag{
    display: flex !important;
  }

  .intl-tel-input .flag-container .arrow{
          display:none !important
      }
      .intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code{
          padding-left: 2px !important;
      }
      .intl-tel-input.allow-dropdown.separate-dial-code .selected-flag{
          display:flex!important
      }

      .intl-tel-input .flag-container .arrow {
        display: none !important;
      }
      .intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
        padding-left: 2px !important;
      }
      .intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
        display: flex !important;
        background-color: transparent !important;
    }

