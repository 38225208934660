.plan-category-btn- {
    display: flex;
    justify-content: center;
    padding-bottom: 60px;
}

.plan-category-btn->button {
    color: #fff;
    width: 100%;
    height: 48px;
    padding: 0px;
    margin-top: 1rem;
    border-radius: 12px;
    text-transform: none;
    background-color: #0057D3 !important;
    outline: none;
    border: #0057D3;
}


.css-rjulde-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select {
    padding: 0px !important;
}

.plan-category-dropdown {
    margin-top: 16px;
}