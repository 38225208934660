.add-btn {
  color: #fff;
  width: 122px;
  padding: 10px;
  margin-top: 0rem;
  border: none;
  border-radius: 12px;
  text-transform: none;
  background-color: black !important;
  margin: 0;
  font-size: 1rem;
  line-height: 1.625;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #344767;
  font-weight: 700;
  letter-spacing: 0.0075em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #ffffff;
}

.filter-add-btn {
  color: #fff;
  padding: 8px 20px;
  margin: 0px 12px;
  margin-top: 0rem;
  border: none;
  border-radius: 8px;
  text-transform: none;
  background-color: #1a73e8 !important;
  font-size: 1rem;
  line-height: 1.625;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #344767;
  font-weight: 700;
  letter-spacing: 0.0075em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #ffffff;
}

.input-heading-wrapper > h4 {
  font-size: 14px;
  line-height: 1.625;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #344767;
  margin-bottom: 4px;
}

.fileters-left-heading{
    font-size: 14px;
    line-height: 1.625;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: #344767;
    margin-bottom: 4px;
}

.fileters-count-number{
    margin-left: 16px;
    margin-right: 16px;
}

.payment-history-date-selection {
  width: 100%;
  max-width: 200px;
  margin-bottom: 12px;
}

.fileters-button{
    display: flex;
    align-items: center;
    background-color: transparent;
    outline: none;
    border: none;
}

.payment-history-name-selection {
  width: 100%;
  max-width: 200px;
  margin-bottom: 12px;
  height: 30px;
}

.user-sign-up-listing {
  position: absolute;
  top: 30px;
  right: 0;
  padding: 12px;
  background-color: white;
  z-index: 9999;
  border-radius: 15px;
}

.fileters-bottom-warrper {
  padding: 24px;
}

@media screen and (max-width: 480px) {
  .payment-history-date-selection {
    width: 100%;
    max-width: 180px;
  }
  .payment-history-name-selection {
    width: 100%;
    max-width: 180px;
    height: 30px;
  }
}
