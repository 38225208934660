.form-wrapper {
    width: 100%;
    padding: 50px 15px 150px 15px;
    box-shadow: 0rem 0.1875rem 0.1875rem 0rem rgb(26 115 232 / 15%), 0rem 0.1875rem 0.0625rem -0.125rem rgb(26 115 232 / 20%), 0rem 0.0625rem 0.3125rem 0rem rgb(26 115 232 / 15%);
    border: 12px solid #1A73E8;

    border-radius: 0.375rem;
}

.form-main-wrapper {
    /* padding-top: 50px; */
}

.col-main-wrapper {
    /* margin-bottom: 8px; */
}

.main-heading>h4 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 24px;
    letter-spacing: .15px;
    color: black;
    /* margin-bottom: 32px; */
    /* margin-top: 16px; */
    display: flex;
    justify-content: center;
    margin-bottom: 0;
}



.form-heading>h4 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: .15px;
    color: black;
    margin-bottom: 0;
    margin-top: 6px;

}

.form-select- {
    margin-bottom: 16px;
}

.form-select->select {
    /* margin: 0px 0px; */
    width: 100%;
    height: 30px;

    font-family: 'Roboto';
    font-weight: normal;
    font-style: bold;
    font-size: 16px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    color: linear-gradient(195deg, #49a3f1, #1A73E8);

    background: #fff;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 8px;
}

.form-select->input {
    /* margin: 8px 0px; */
    width: 100%;
    height: 30px;

    font-family: 'Roboto';
    font-weight: normal;
    font-style: bold;
    font-size: 12px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    color: linear-gradient(195deg, #49a3f1, #1A73E8);

    background: #fff;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 8px;
}

.form-select->input:focus-visible {
    /* margin: 8px 0px; */
    width: 100%;
    height: 30px;

    font-family: 'Roboto';
    font-weight: normal;
    font-style: bold;
    font-size: 12px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    color: linear-gradient(195deg, #49a3f1, #1A73E8);

    background: #fff;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 8px;
    outline: none;
}


.form-select->select:focus-visible {
    /* margin: 0px 0px; */
    width: 100%;
    height: 30px;

    font-family: 'Roboto';
    font-weight: normal;
    font-style: bold;
    font-size: 16px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    color: linear-gradient(195deg, #49a3f1, #1A73E8);

    background: #fff;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 8px;
    outline: none;
}