.plan-upgrader-btn {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}

.plan-upgrader-main-wrapper {
  padding-top: 40px !important;
}

.plan-upgrader-btn > button {
  color: #fff;
  width: 222px;
  height: 48px;
  padding: 10px;
  margin-top: 1rem;
  border-radius: 12px;
  text-transform: none;
  background-color: #0057d3 !important;
  outline: none;
  border: #0057d3;
}
