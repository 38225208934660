:root {
    --color-blue: #0057D3;
    --color-background: lightgray;
    --small: 14px;
    --form-label: 15px;
    --medium: 16px;
    --large: 24px;
    --light-blue: #93C0FF;
    --white: #FFFFFF;
	--block: #000000;
	--red:   red;
	--rightside: #E1F5FE;

  }


.imageuplode{
    width: 30px;
    height: 30px;
}

.profile-text{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 90px;
    margin-top: 20px !important;
}



  .closeBtnProfile {
	float: right;
	margin-top: -30px;
	margin-left: 10%;
	background-color: transparent;
	border: none;
	position: absolute;
	color: #988f8f;
}
.fname-lname {
    display: flex !important;
    /* justify-content: space-between; */
}
.img-size-profile {
	width: 98px;
	height: 98px;
	border-radius: 50% !important;
	
}
.profilewidth{
	min-width: 320px !important;
    background-color: var(--white);
	border: 1px solid var(--white);
	border-radius: 25px;
	width: 600px !important;
	height: 600px;
	overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button-update{
    text-align: center;
    margin-top: 0px;
}
.Last_Name{
    width: 205px;
    height: 48px;
    /* padding-left: 5px; */
    border-radius: 8px !important;
}
.first_Name{
     width: 205px;
    height: 48px; 
    border-radius: 8px !important;
    padding-right: 10px!important;
}

.E-mail{
    width: 410px;
    height: 48px;
    border-radius: 8px !important;
}
.name-fild{
    font-size: 0.9rem !important;
    margin-left: 8px !important;
    margin-bottom: 6px !important;
    padding-top: 0px !important;
}
.add-imge{
    position: relative;
    top: -18px;
    left: 0px;
}
/* .join-meeting{
    display: flex;
    justify-content: center;
    align-items: center;
   
    font-size: 24px !important;
    line-height: 33px !important;
    font-weight: 600!important;
} */
.Meeting-link{
    width: 100% !important;
    height: 48px !important;
    border-radius: 8px !important;
}
.meeting{
    display: flex;
    flex-direction: column;

}
.join-meeting {
    width: 420px; 
    height: 48px; 
    border-radius: 8px;
}
.meeting-log{
    position: relative;
}
@media only screen and (max-width: 768px){
    .join-meeting {
        width: 100%; 
        height: 48px; 
        border-radius: 8px;
    }
    
}
.CloseIcon{
    width: 30px !important;
    height: 16px !important;
}
@media only screen and (max-width: 430px){
 
    .E-mail{
        width: 400px;
        height: 48px;
        border-radius: 8px !important;
    }
    .Last_Name{
        width: 80%;
        height: 48px;
        border-radius: 8px !important;
    }
    .first_Name{
        width: 80%;
        height: 48px;
        border-radius: 8px !important;
    }
}
@media only screen and (max-width: 390px) and (min-width: 280px)  {
    .E-mail{
        width: 100%;
        height: 48px;
        border-radius: 8px !important;
    }
}
.extra-class{
    color: var(--block);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px !important;
    font-weight: 400 !important;
}


@media only screen and (max-width: 599px) {
	.closeBtnProfile {
		margin-left: 28%;}}

        @media only screen and (max-width: 599px) and (min-width: 280px){
            .profile-text{
                padding-left: 30px;
            }
        }



        